<div class="container-fluid">
  <div class="row h-100 d-flex justify-content-center align-items-center">
    <div class="div col-md-4">
      <div class="login-container d-flex flex-column">
        <amplify-authenticator
          *ngIf="authState !== 'signedin'"
        ></amplify-authenticator>
      </div>
    </div>
  </div>
</div>
