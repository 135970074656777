import {
  Component,
  ChangeDetectorRef,
  OnInit,
  NgZone,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';

import {
  onAuthUIStateChange,
  CognitoUserInterface,
} from '@aws-amplify/ui-components';
import { Auth } from 'aws-amplify';
import { UserService } from '../../user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  @ViewChild('authenticator') public authenticator: any;
  public authState;
  public user: CognitoUserInterface | undefined;

  constructor(
    private ref: ChangeDetectorRef,
    private userService: UserService,
    private router: Router,
    private ngZone: NgZone,
  ) { }

  public ngOnInit(): void {
    onAuthUIStateChange((authState, authData) => {
      this.userService.authState = authState;
      this.userService.user = authData as CognitoUserInterface;
      this.authState = authState;
      this.user = authData as CognitoUserInterface;
      this.ref.detectChanges();
      if (authState === 'signedin') {
        Auth.currentSession().then((session) => {
          this.userService.token.next(session.getAccessToken().getJwtToken());
          const decodedPayload: any = session.getAccessToken().decodePayload();
          this.userService.isAdmin = !!(
            decodedPayload['cognito:groups'] &&
            decodedPayload['cognito:groups'].includes('admin')
          );
        });
        this.redirectToApp();
      }
    });
  }

  private redirectToApp(): void {
    this.ngZone.run(() => this.router.navigateByUrl('app'));
  }
}
