import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Destroyable } from '../../destroyable.class';
import { UserService } from '../../../user.service';

@Component({
    selector: 'app-top-bar',
    templateUrl: './top-bar.component.html',
    styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent extends Destroyable implements OnInit {
    @Input() public title: string;

    constructor(private user: UserService) {
        super();
    }

    ngOnInit() {}

    async logout() {
        return await this.user.logout();
    }
}
