import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {
    faArrowCircleRight,
    faAt,
    faEye,
    faFlagUsa,
    faGlobe,
    faHeart,
    faMapMarkedAlt,
    faMapMarker,
    faMapMarkerAlt,
    faMobileAlt,
    faShareAltSquare,
    faUsers,
    faUserSlash
} from '@fortawesome/free-solid-svg-icons';
import {faLinkedin, faTwitter} from '@fortawesome/free-brands-svg-icons';

import {ILeadSearch} from '../../interfaces/lead-search.interface';
import {Destroyable} from '../../destroyable.class';

@Component({
    selector: 'app-semantic-search-card',
    templateUrl: './semantic-search-card.component.html',
    styleUrls: ['./semantic-search-card.component.scss']
})
export class SemanticSearchCardComponent extends Destroyable implements OnInit {
    @Input() public semanticSearch: ILeadSearch;
    @Input() public selected: boolean;
    @Input() public invisibleLookALike: boolean;
    @Output() public lookALike: EventEmitter<string> = new EventEmitter<string>();
    public icons = {
        faArrowCircleRight,
        faAt,
        faMobileAlt,
        faMapMarkerAlt,
        faHeart,
        faEye,
        faUserSlash,
        faUsers,
        faMapMarker,
        faFlagUsa,
        faShareAltSquare,
        faGlobe,
        faLinkedin,
        faTwitter,
        faMapMarkedAlt
    };
    public keywords: string[] = [];
    public personaeCOI: string[] = [];
    public personaeTopics: string[] = [];
    public personaeAccuracy = 0;
    public addressField: string;
    public cityStatePostalAddress: string;

    constructor() {
        super();
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.personaeCOI = this.semanticSearch.Personae_COI ? this.semanticSearch.Personae_COI.split(',') : [];
            this.personaeTopics = this.semanticSearch.Personae_Topics ? this.semanticSearch.Personae_Topics.split(',').splice(0, 2) : [];
            this.keywords = this.semanticSearch.Company_Lexicals ? this.semanticSearch.Company_Lexicals.split(',') : [];
            this.personaeAccuracy = Math.round(Number(this.semanticSearch.Personae_Accuracy) * 100);
            this.addressField = `${this.semanticSearch.Address1} ${this.semanticSearch.Address2}`;
            this.cityStatePostalAddress = `${this.semanticSearch.City} ${this.semanticSearch.State} ${this.semanticSearch.Postal_Code}`;
        }, 1000);
    }

    public getLookALike(companyName: string, jobTitle: string): void {
        this.lookALike.emit(`${companyName} ${jobTitle}`);
    }

    public getIconName(type: string): string {
        if (type) {
            if (type.length === 0 || type === 'empty') {
                return 'empty.png';
            }
            const iconType: string = type.replace(' ', '').replace('-', '').replace('_', '').toLowerCase();

            return `${iconType}.png`;
        } else {
            return 'empty.png';
        }
    }
}
