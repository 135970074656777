<mat-card class="d-flex flex-column-reverse" *ngIf="semanticSearch" [ngClass]="{ selected: selected }">
    <div class="d-flex flex-column flex-md-row justify-content-between">
        <div class="semantic-grid">
            <div class="w-100">
                <div class="semantic-header">
                    <h5 class="job-title-row gray-text width-to-crop crop-text ga-h5 ffws700" [matTooltip]="semanticSearch.Job_Title">
                        {{ semanticSearch.Job_Title }}
                    </h5>
                    <div class="d-flex align-items-center ga-title">
                        <span class="name-row crop-text width-to-crop ffws700" [matTooltip]="semanticSearch.First_Name + ' ' + semanticSearch.Last_Name"
                            >{{ semanticSearch.First_Name }} {{ semanticSearch.Last_Name }}</span
                        >
                    </div>
                    <div class="d-flex flex-column align-items-center icon-container ga-i">
                        <img
                            class="person-icon"
                            [src]="'assets/person-icons/' + getIconName(semanticSearch.Personae)"
                            [matTooltip]="semanticSearch.Personae + ' ' + personaeAccuracy + '%'"
                        />
                        <div class="d-flex justify-content-center">
                            <small class="personae-title">{{ semanticSearch.Personae || 'undefined' }}</small>
                            <span class="accuracy" *ngIf="personaeAccuracy && personaeAccuracy > 0">{{ personaeAccuracy }}%</span>
                        </div>
                    </div>
                </div>

                <p class="info-row crop-text width-to-crop grid-important semantic-detail w-100" [matTooltip]="semanticSearch.Email">
                    <fa-icon [icon]="icons.faAt"></fa-icon>
                    <span class="mp-clear">{{ semanticSearch.Email || 'n/a' }}</span>
                </p>
                <p class="info-row crop-text grid-important semantic-detail w-100" [matTooltip]="semanticSearch.Phone">
                    <fa-icon class="phone-icon" [icon]="icons.faMobileAlt"></fa-icon>
                    <span class="mp-clear" *ngIf="semanticSearch.Phone !== ' ' && semanticSearch.Phone !== ''">{{ semanticSearch.Phone }}</span>
                    <span class="mp-clear" *ngIf="semanticSearch.Phone === ' ' || semanticSearch.Phone === ''">n/a</span>
                </p>
                <p class="info-row crop-text grid-important semantic-detail w-100" [matTooltip]="semanticSearch.Linked_In_Url">
                    <fa-icon [icon]="icons.faLinkedin"></fa-icon>
                    <a class="card-link mp-clear info-row" [href]="semanticSearch.Linked_In_Url" target="_blank">{{ semanticSearch.Linked_In_Url || 'n/a' }}</a>
                </p>

                <hr class="line" />

                <p class="info-row crop-text grid-important semantic-detail w-100" [matTooltip]="semanticSearch.Personae_Brands">
                    <fa-icon [icon]="icons.faHeart"></fa-icon>
                    <span class="mp-clear">{{ semanticSearch.Personae_Brands || 'n/a' }}</span>
                </p>
                <div class="d-flex semantic-topics personae-keywords">
                    <h5 class="gray-text intents crop-text ffws700">
                        Intents
                    </h5>
                    <div class="topics">
<!--                        <app-keyword class="topics-keyword" *ngFor="let keyword of personaeCOI" [textValue]="keyword"></app-keyword>-->
                        <app-keyword class="topics-keyword" *ngFor="let keyword of keywords.slice(0,2)" [textValue]="keyword"></app-keyword>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-column align-items-center justify-content-between col-md-3 w-100">
                <div class="d-flex flex-wrap justify-content-center w-80">
                    <h5 class="text-center gray-text width-to-crop crop-text ffws700">
                        Topics
                    </h5>
                    <app-keyword class="" *ngFor="let keyword of keywords" [textValue]="keyword" class="mx-1"></app-keyword>
                </div>
                <p class="info-row hiring">
                    <img
                        [src]="semanticSearch.Company_Hiring === 'True' ? 'assets/hiring.png' : 'assets/not-hiring.png'"
                        [matTooltip]="semanticSearch.Company_Hiring === 'True' ? 'Hiring' : 'No hiring'"
                    />
                </p>
            </div>
            <div class="d-flex flex-column col-md-4 w-100 my-4">
                <p class="info-row name-row ffws700">
                    <span class="p-0">{{ semanticSearch.Company_Name }}</span>
                </p>

                <p class="info-row crop-text name-row" [matTooltip]="semanticSearch.Company_Industry">
                    <span class="p-0 cell-header">{{ semanticSearch.Company_Industry }}</span>
                </p>

                <p class="info-row crop-text name-row" [matTooltip]="semanticSearch.Company_NB_Employees">
                    <span class="job-title-row p-0 cell-header">Size</span>
                    <span class="cell-header-info">{{ semanticSearch.Company_NB_Employees }}</span>
                </p>
                <p class="info-row crop-text name-row" [matTooltip]="semanticSearch.Company_Revenue">
                    <span class="job-title-row p-0 cell-header">Revenue</span>
                    <span class="cell-header-info" *ngIf="semanticSearch.Company_Revenue !== 'Undefined'">{{ semanticSearch.Company_Revenue }}</span>
                    <span class="cell-header-info" *ngIf="semanticSearch.Company_Revenue === 'Undefined'">n/a</span>
                </p>
                <p class="info-row crop-text grid-important semantic-detail mp-clear" [matTooltip]="addressField">
                    <fa-icon class="address-icon" [icon]="icons.faMapMarkerAlt"></fa-icon>
                    <span class="text-truncate" *ngIf="addressField !== '- ' && addressField !== ' '">{{ addressField }}</span>
                    <span class="text-truncate" *ngIf="addressField === '- ' || addressField === ' '">n/a</span>
                </p>
                <p class="info-row crop-text grid-important semantic-detail mp-clear" [matTooltip]="cityStatePostalAddress">
                    <fa-icon [icon]="icons.faMapMarkedAlt"></fa-icon>
                    <span class="text-truncate" *ngIf="cityStatePostalAddress !== '- - -'">{{ cityStatePostalAddress }}</span>
                    <span class="text-truncate" *ngIf="cityStatePostalAddress === '- - -'">n/a</span>
                </p>
                <p class="info-row crop-text grid-important semantic-detail mp-clear" [matTooltip]="semanticSearch.Country">
                    <fa-icon [icon]="icons.faFlagUsa"></fa-icon>
                    <span>{{ semanticSearch.Country }}</span>
                </p>
                <p class="info-row crop-text grid-important semantic-detail mp-clear" [matTooltip]="semanticSearch.Company_HQ_Phone">
                    <fa-icon class="phone-icon" [icon]="icons.faMobileAlt"></fa-icon>
                    <span>{{ semanticSearch.Company_HQ_Phone || 'n/a' }}</span>
                </p>
                <p
                    class="info-row crop-text grid-important semantic-detail mp-clear"
                    [matTooltip]="semanticSearch.Company_Website && semanticSearch.Company_Website !== '-' ? semanticSearch.Company_Website : 'n/a' || 'n/a'"
                >
                    <fa-icon class="web-icon" [icon]="icons.faGlobe"></fa-icon>
                    <a class="card-link" [href]="semanticSearch.Company_Website" target="_blank">{{
                        semanticSearch.Company_Website && semanticSearch.Company_Website !== '-' ? semanticSearch.Company_Website : 'n/a' || 'n/a'
                    }}</a>
                </p>
            </div>
        </div>
    </div>
    <button mat-flat-button class="load-column-button ffws700" (click)="getLookALike(semanticSearch.Company_Name, semanticSearch.Job_Title)" *ngIf="!invisibleLookALike">
        <fa-icon [icon]="icons.faArrowCircleRight" class="green"></fa-icon>
        <span [ngClass]="{ 'extra-bold': selected }">LOOK A LIKE</span>
    </button>
</mat-card>
