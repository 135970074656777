import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {BehaviorSubject, Observable} from 'rxjs';

import {environment} from '../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    public filters: { key: string; value: string }[] = [];
    public filterToAssign$: BehaviorSubject<any> = new BehaviorSubject<any>({});
    public payload$: BehaviorSubject<any> = new BehaviorSubject<any>({});
    public completeToggler$: BehaviorSubject<boolean> = new BehaviorSubject<any>(true);
    public showActiveFilters$ = new BehaviorSubject<any>(false);
    public isLoading$ = new BehaviorSubject<boolean>(null);
    public search_phrase: any = '';

    constructor(private http: HttpClient) {
    }

    public createPayload(): {} {
        const payload = {
            search_phrase: this.search_phrase || '',
            city: null,
            page_size: 50,
            start_from: 0,
            include_fields: null,
            exclude_fields: null,
            complete: this.completeToggler$.value
        };

        return Object.assign(payload, this.filterToAssign$.value);
    }

    public getSearchData(): Observable<any> {
        return this.http.post<any>(`${environment.api}/think-abm/search?format=json`, this.createPayload());
    }

    public getFilterDate(query: string, settings?: string): Observable<any> {
        const url = settings ? `${environment.api}/think-abm/documents/distinct-settings/?field=${query}` : `${environment.api}/think-abm/documents/distinct/?field=${query}`;
        return this.http.get(url);
    }

    public getFilterDateFromIdentify(query: string): Observable<any> {
        return this.http.get(`${environment.api_template}?field=${query}`);
    }

    public getExcelFile(): Observable<any> {
        return this.http.post<any>(`${environment.api}/think-abm/search?format=xlsx`, Object.assign(this.createPayload(), {page_size: 10000}), {
            responseType: 'blob' as 'json',
        });
    }

    public uploadExcelFile(file: FormData) {
        return this.http.post(`${environment.api}/think-abm/documents/uploadfile/`, file);
    }

    public getDocumentsCount(): Observable<any> {
        return this.http.post(`${environment.api}/think-abm/documents/count`, {});
    }

    public getCompaniesCount(): Observable<any> {
        return this.http.get(`${environment.api}/think-abm/companies/count/`);
    }

    public getDocumentsDetected(): Observable<any> {
        return this.http.get(`${environment.api}/think-abm/documents/detected?field_name=Personae`);
    }
}
