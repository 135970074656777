import { Component, Input } from '@angular/core';
import { EMPTY } from 'rxjs';
import { first, catchError } from 'rxjs/operators';
import { ApiService } from '../../../api.service';

@Component({
  selector: 'app-search-result-header',
  templateUrl: './search-result-header.component.html',
  styleUrls: ['./search-result-header.component.scss']
})
export class SearchResultHeaderComponent {
  @Input() public searchCount: number;
  @Input() public searchQuery: string;

  constructor(private api: ApiService) { }

  downloadExcelFile() {
    this.api
      .getExcelFile()
      .pipe(
        first(),
        catchError(async () => EMPTY)
      )
      .subscribe((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'file.xlsx';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      });
  }
}
