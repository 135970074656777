<section class="filters-flex">
    <div
        class="filters-width"
        [class.filters-width__margin-bottom]="
            !(form.get('country').value?.length > 0) &&
            !(form.get('industry').value?.length > 0) &&
            !(form.get('companySize').value?.length > 0) &&
            !(form.get('jobTitle').value?.length > 0) &&
            !(form.get('jobFunction').value?.length > 0) &&
            !(selectedPersonae?.length > 0) &&
            !(selectedPersonaeCOIFilter?.length > 0) &&
            !(selectedPersonaeTopicsFilter?.length > 0) &&
            !(selectedLexicals?.length > 0) &&
            !(selectedRevenues?.length > 0)
        "
    >
        <form [formGroup]="form" class="grid-filters" autocomplete="off">
            <div class="multiselect-container w-max-ms">
                <p-multiSelect
                    [options]="countryOptions"
                    formControlName="country"
                    optionLabel="name"
                    [displaySelectedLabel]="false"
                    [virtualScroll]="true"
                    itemSize="30"
                    placeholder="Country"
                    styleClass="multiselect; {{ this.form.get('country').value?.length > 0 ? 'p-multiselect__active' : null }}"
                    (onPanelHide)="this.form.get('country').value?.length > 0 ? submitFilters() : ''"
                >
                </p-multiSelect>
                <span class="arrow-icon arrow down"></span>
            </div>

            <div class="multiselect-container w-max-ms">
                <p-multiSelect
                    [options]="industryOptions"
                    formControlName="industry"
                    optionLabel="name"
                    [displaySelectedLabel]="false"
                    placeholder="Industry"
                    [virtualScroll]="true"
                    itemSize="30"
                    styleClass="multiselect; {{ this.form.get('industry').value?.length > 0 ? 'p-multiselect__active' : null }}"
                    (onPanelHide)="this.form.get('industry').value?.length > 0 ? submitFilters() : ''"
                >
                </p-multiSelect>
                <span class="arrow-icon arrow down"></span>
            </div>

            <div class="multiselect-container w-max-auto">
                <p-multiSelect
                    [options]="companySizeOptions"
                    formControlName="companySize"
                    optionLabel="name"
                    [displaySelectedLabel]="false"
                    placeholder="Company Size"
                    styleClass="multiselect; {{ this.form.get('companySize').value?.length > 0 ? 'p-multiselect__active' : null }}"
                    [virtualScroll]="true"
                    itemSize="30"
                    (onPanelHide)="this.form.get('companySize').value?.length > 0 ? submitFilters() : ''"
                >
                </p-multiSelect>
                <span class="arrow-icon arrow down"></span>
            </div>

            <div class="multiselect-container w-max-ms">
                <p-multiSelect
                    [options]="jobTitleOptions"
                    formControlName="jobTitle"
                    optionLabel="name"
                    [displaySelectedLabel]="false"
                    placeholder="Job Title"
                    styleClass="multiselect {{ this.form.get('jobTitle').value?.length > 0 ? 'p-multiselect__active' : null }}"
                    [virtualScroll]="true"
                    itemSize="30"
                    (onPanelHide)="this.form.get('jobTitle').value?.length > 0 ? submitFilters() : ''"
                >
                </p-multiSelect>
                <span class="arrow-icon arrow down"></span>
            </div>

            <div class="multiselect-container">
                <p-multiSelect
                    [options]="jobFunctionOptions"
                    formControlName="jobFunction"
                    optionLabel="name"
                    [displaySelectedLabel]="false"
                    placeholder="Management Level"
                    styleClass="multiselect; {{ this.form.get('jobFunction').value?.length > 0 ? 'p-multiselect__active' : null }}"
                    [virtualScroll]="true"
                    itemSize="30"
                    (onPanelHide)="this.form.get('jobFunction').value?.length > 0 ? submitFilters() : ''"
                >
                </p-multiSelect>
                <span class="arrow-icon arrow down"></span>
            </div>

            <div
                class="more-filters-container"
                [class.more-filters-container__chosen]="
                    selectedLexicals?.length > 0 ||
                    selectedRevenues?.length > 0 ||
                    selectedPersonae?.length > 0 ||
                    selectedPersonaeCOIFilter?.length > 0 ||
                    selectedPersonaeTopicsFilter?.length > 0
                "
            >
                <div class="front-more-filters" (click)="toggleMoreFilters($event)">
                    <span class="more-filters-title">More Filters</span>
                    <span class="arrow-icon__more-filters arrow down"> </span>
                    <span></span>
                    <span class="more-filters-arrow" *ngIf="isMoreFiltersVisible"></span>
                </div>
                <div class="more-filters-content" *ngIf="isMoreFiltersVisible && whichFilters === 'company'" [class.more-filters-content-company]="whichFilters === 'company'">
                    <div>
                        <div class="custom-filter">
                            <span class="custom-filter__title">Company Expertise</span>
                            <span class="p-input-icon-right custom-filter__input-box">
                                <i class="pi pi-search"></i>
                                <input type="text" class="custom-filter__input" [formControl]="this.form.get('companyLexicals')" pInputText placeholder="Company Expertise" />
                            </span>
                            <div class="custom-filter__select-box">
                                <ul role="listbox" *ngFor="let lexical of lexicalsFiltered" class="custom-filter__listbox">
                                    <li class="custom-filter__listbox__item">
                                        <p-checkbox
                                            [(ngModel)]="selectedLexicals"
                                            [ngModelOptions]="{ standalone: true }"
                                            [value]="lexical.name"
                                            [inputId]="lexical.name"
                                        ></p-checkbox>
                                        <label class="custom-filter__checkbox-label" [for]="lexical.name">{{ lexical.name }}</label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="custom-filter">
                            <span class="custom-filter__title">Revenue</span>
                            <span class="p-input-icon-right custom-filter__input-box">
                                <i class="pi pi-search"></i>
                                <input type="text" class="custom-filter__input" [formControl]="this.form.get('companyRevenue')" pInputText placeholder="Revenue" />
                            </span>
                            <div class="custom-filter__select-box">
                                <ul role="listbox" *ngFor="let revenue of revenuesFiltered" class="custom-filter__listbox">
                                    <li class="custom-filter__listbox__item">
                                        <p-checkbox
                                            [(ngModel)]="selectedRevenues"
                                            [ngModelOptions]="{ standalone: true }"
                                            [value]="revenue.name"
                                            [inputId]="revenue.name"
                                        ></p-checkbox>
                                        <label class="custom-filter__checkbox-label" [for]="revenue.name">{{ revenue.name }}</label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="more-filters-content" *ngIf="isMoreFiltersVisible && whichFilters === 'lead'">
                    <div class="custom-filter__without-box">
                        <span class="custom-filter__title">Personality</span>
                        <div class="custom-filter__select-box__personae">
                            <ul role="listbox" *ngFor="let personae of personaeOptions" class="custom-filter__listbox">
                                <li class="custom-filter__listbox__item">
                                    <p-checkbox
                                        [(ngModel)]="selectedPersonae"
                                        [ngModelOptions]="{ standalone: true }"
                                        [value]="personae.name"
                                        [inputId]="personae.name"
                                    ></p-checkbox>
                                    <label class="custom-filter__checkbox-label" [for]="personae.name">{{ personae.name }}</label>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="custom-filter">
                        <span class="custom-filter__title">Centers of Interests</span>
                        <span class="p-input-icon-right custom-filter__input-box">
                            <i class="pi pi-search"></i>
                            <input type="text" class="custom-filter__input" [formControl]="this.form.get('personae_coi')" pInputText placeholder="Centers of Interests" />
                        </span>
                        <div class="custom-filter__select-box">
                            <ul role="listbox" *ngFor="let coi of personaeCOIFilterFiltered" class="custom-filter__listbox">
                                <li class="custom-filter__listbox__item">
                                    <p-checkbox
                                        [(ngModel)]="selectedPersonaeCOIFilter"
                                        [ngModelOptions]="{ standalone: true }"
                                        [value]="coi.name"
                                        [inputId]="coi.name"
                                    ></p-checkbox>
                                    <label class="custom-filter__checkbox-label" [for]="coi.name">{{ coi.name }}</label>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="custom-filter">
                        <span class="custom-filter__title">Topics</span>
                        <span class="p-input-icon-right custom-filter__input-box">
                            <i class="pi pi-search"></i>
                            <input type="text" class="custom-filter__input" [formControl]="this.form.get('personae_topics')" pInputText placeholder="Topics" />
                        </span>
                        <div class="custom-filter__select-box">
                            <ul role="listbox" *ngFor="let topic of personaeTopicsFilterFiltered" class="custom-filter__listbox">
                                <li class="custom-filter__listbox__item">
                                    <p-checkbox
                                        [(ngModel)]="selectedPersonaeTopicsFilter"
                                        [ngModelOptions]="{ standalone: true }"
                                        [value]="topic.name"
                                        [inputId]="topic.name"
                                    ></p-checkbox>
                                    <label class="custom-filter__checkbox-label" [for]="topic.name">{{ topic.name }}</label>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="more-filters-overlay" (click)="toggleMoreFilters($event)" *ngIf="isMoreFiltersVisible"></div>
            </div>

            <!-- <button mat-button class="apply-filters-button" (click)="submitFilters()">Apply filters</button> -->
            <button mat-button class="reset-filters-button" (click)="resetFilters()">Clear filters</button>
        </form>
    </div>

    <div
        class="filters-flex__active-filters text"
        *ngIf="
            form.get('country').value?.length > 0 ||
            form.get('industry').value?.length > 0 ||
            form.get('companySize').value?.length > 0 ||
            form.get('jobTitle').value?.length > 0 ||
            form.get('jobFunction').value?.length > 0 ||
            selectedLexicals?.length > 0 ||
            selectedRevenues?.length > 0 ||
            selectedPersonae?.length > 0 ||
            selectedPersonaeCOIFilter?.length > 0 ||
            selectedPersonaeTopicsFilter?.length > 0
        "
    >
        <span class="active-chips">Active Filters:</span>
        <div class="chips" *ngFor="let item of form.get('country').value">
            <span>{{ item.name }} </span>
            <span class="chips__delete" (click)="deleteChips(item, form.get('country'))">x</span>
        </div>
        <div class="chips" *ngFor="let item of form.get('industry').value">
            <span>{{ item.name }} </span>
            <span class="chips__delete" (click)="deleteChips(item, form.get('industry'))">x</span>
        </div>
        <div class="chips" *ngFor="let item of form.get('companySize').value">
            <span>{{ item.name }} </span>
            <span class="chips__delete" (click)="deleteChips(item, form.get('companySize'))">x</span>
        </div>
        <div class="chips" *ngFor="let item of form.get('jobTitle').value">
            <span>{{ item.name }} </span>
            <span class="chips__delete" (click)="deleteChips(item, form.get('jobTitle'))">x</span>
        </div>
        <div class="chips" *ngFor="let item of form.get('jobFunction').value">
            <span>{{ item.name }} </span>
            <span class="chips__delete" (click)="deleteChips(item, form.get('jobFunction'))">x</span>
        </div>
        <div class="chips" *ngFor="let item of selectedLexicals">
            <span>{{ item }} </span>
            <span class="chips__delete" (click)="deleteChips(item, selectedLexicals)">x</span>
        </div>
        <div class="chips" *ngFor="let item of selectedRevenues">
            <span>{{ item }} </span>
            <span class="chips__delete" (click)="deleteChips(item, selectedRevenues)">x</span>
        </div>
        <div class="chips" *ngFor="let item of selectedPersonae">
            <span>{{ item }} </span>
            <span class="chips__delete" (click)="deleteChips(item, selectedPersonae)">x</span>
        </div>
        <div class="chips" *ngFor="let item of selectedPersonaeCOIFilter">
            <span>{{ item }} </span>
            <span class="chips__delete" (click)="deleteChips(item, selectedPersonaeCOIFilter)">x</span>
        </div>
        <div class="chips" *ngFor="let item of selectedPersonaeTopicsFilter">
            <span>{{ item }} </span>
            <span class="chips__delete" (click)="deleteChips(item, selectedPersonaeTopicsFilter)">x</span>
        </div>
    </div>
</section>
