<div class="d-flex flex-column filter-container">
    <mat-form-field appearance="fill" [ngClass]="{ selected: selected }">
        <input type="text" matInput [placeholder]="label" [formControl]="form.get(controlName)" [matAutocomplete]="auto" #input />
        <button class="close-icon" mat-button *ngIf="input.value.length > 0" matSuffix mat-icon-button aria-label="Clear" (click)="reset()">
            <mat-icon>close</mat-icon>
        </button>
        <button class="close-icon" mat-button *ngIf="input.value.length === 0" matSuffix mat-icon-button aria-label="Toggle">
            <mat-icon>keyboard_arrow_down</mat-icon>
        </button>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onFilterSelected($event)">
            <mat-option class="filter-option" *ngFor="let option of options$ | async" [value]="option">
                {{ option }}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</div>
