import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../api.service';
import { Destroyable } from '../../destroyable.class';
import { delay, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss']
})
export class SearchBoxComponent extends Destroyable implements AfterViewInit {
  @ViewChild('inputElement') public inputElement: ElementRef<HTMLInputElement>;
  @Output() public search: EventEmitter<string> = new EventEmitter<string>();

  public form: FormGroup = new FormGroup({
    query: new FormControl('')
  });
  public icon = faSearch;

  constructor(private api: ApiService) {
    super();
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.inputElement.nativeElement.focus();
    }, 0);
  }

  completeTogglerForm = new FormControl(false, Validators.required);

  completeStateToggler() {
    this.api.completeToggler$.next(this.completeTogglerForm.value);
  }

  public searchSemantic(value: string): void {
    this.api.search_phrase = this.inputElement.nativeElement.value;
    this.api.filterToAssign$.pipe(takeUntil(this.destroyed$)).subscribe(() => this.search.emit(this.inputElement.nativeElement.value));
    // this.search.emit(value);
  }

  public performSearch(event: any, value: string): void {
    event.preventDefault();

    if (value.length > 0) {
      this.searchSemantic(value);
    }
  }
}
