import { Injectable, OnInit } from '@angular/core';
import { CognitoUserInterface } from '@aws-amplify/ui-components';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { IUserLogin } from './shared/interfaces/user.interface';
import { Auth } from 'aws-amplify';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserService implements OnInit {
  public token: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public isAdmin: boolean = false;
  queryLimit$ = new BehaviorSubject<number>(null);

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.getSubscriptionInfo()
      .pipe(take(1))
      .subscribe(({ query_limit }) => this.queryLimit$.next(query_limit));
  }

  public get authState(): string {
    return this._authState;
  }

  public set authState(authState: string) {
    this._authState = authState;
    this.authState$.next(this._authState);
  }

  public get user(): CognitoUserInterface {
    return this._user;
  }

  public set user(user: CognitoUserInterface) {
    this._user = user;
    this.user$.next(this._user);
  }

  public authState$: Subject<string> = new BehaviorSubject<string>(null);
  public user$: Subject<CognitoUserInterface> =
    new BehaviorSubject<CognitoUserInterface>(null);

  private _authState: string;
  private _user: CognitoUserInterface;

  getSubscriptionInfo(): Observable<any> {
    return this.http.get(`${environment.api}/think-abm/stripe/subscription`);
  }

  public login(user: IUserLogin): Promise<any> {
    return Auth.signIn({
      username: user.login,
      password: user.password,
    });
  }

  public logout(): Promise<any> {
    return Auth.signOut();
  }
}
