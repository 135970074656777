import {
  Directive,
  HostBinding,
  HostListener,
  Output,
  EventEmitter,
} from '@angular/core';
@Directive({
  selector: '[appDrag]',
})
export class DragDirective {
  @Output() files: EventEmitter<File> = new EventEmitter();

  @HostBinding('style.border') private border = '4px #ddd dashed';

  @HostListener('dragover', ['$event']) public onDragOver(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.border = '4px #2e49d8 dashed';
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.border = '4px #ddd dashed';
  }

  @HostListener('drop', ['$event']) public onDrop(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();

    const file = evt.dataTransfer.files[0];

    this.files.emit(file);
    this.border = '4px #ddd dashed';
  }
}
