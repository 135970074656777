<div class="card-top-spoiler"></div>
<mat-card
  class="d-flex flex-column align-items-center p-2 justify-content-between"
>
  <div>
    <div
      class="
        w-100
        d-flex
        mt-4
        flex-column
        justify-content-center
        align-items-center
      "
    >
      <img [src]="logoSrc" alt="Logo" />
      <h3 class="text-center">
        Think <span>{{ planName }}</span>
      </h3>
    </div>
    <div class="text-center">
      <p class="mb-0 large-price-text">
        {{ price > 0 ? "$ " + price : "Free" }}
      </p>
      <p class="small-price-text mb-0" [ngClass]="{ transparent: !subtitle }">
        {{ subtitle || "n/a" }}
      </p>

      <ul class="p-0">
        <li *ngFor="let point of bulletpoints" class="bulletpoint">
          <div class="point"></div>
          {{ point }}
        </li>
      </ul>
    </div>
  </div>

  <button
    mat-raised-button
    color="primary"
    class="mt-3 plan-button"
    (click)="navigate()"
  >
    Start for free
  </button>
</mat-card>
