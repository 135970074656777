import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-payment-modal',
  templateUrl: './payment-modal.component.html',
  styleUrls: ['./payment-modal.component.scss'],
})
export class PaymentModalComponent {
  @Input() public paymentResponse: 'success' | 'failed';

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { paymentResponse: 'success' | 'failed' },
    private matDialogRef: MatDialogRef<PaymentModalComponent>,
  ) {}

  public close(): void {
    this.matDialogRef.close();
  }
}
