<div class="container">
    <!-- <div class="upload-tooltip" *ngIf="!isAddNew && !isEdit && !isDNC">
        <span class="upload-tooltip-text">You may upload: New leads and/or Rejected Leads</span>
    </div> -->
    <div class="data-upload">
        <div class="upload">
            <h4 class="title">Upload new leads</h4>
            <!-- <h4 class="title" *ngIf="!isLeads">{{ title }}</h4> -->
            <div class="drag-drop-input">
                <input id="data-upload" type="file" class="input" accept=".xlsx, .csv" #upload />
                <label for="data-upload" class="inside-input">
                    <div class="custom-input" appDrag (files)="filesDropped($event)">
                        <img src="../../assets/data-upload/data-upload.svg" class="icon" alt="" />
                        <div class="drag-text">
                            <p class="browse-text">
                                Drag and drop your files here or
                                <span class="browse-text underline">browse</span> for a file to upload
                            </p>
                        </div>
                        <div class="drag-text">
                            <span> only .xls or .csv files, max 10MB </span>
                        </div>
                    </div>
                </label>
            </div>
        </div>
    </div>
</div>
