<form class="d-grid search-box-field" (submit)="performSearch($event, inputElement.value)" [formGroup]="form">
    <div class="d-flex search-box-field__row">
        <mat-form-field class="search-box-field form-field" appearance="fill">
            <input matInput placeholder="Enter your query" #inputElement formControlName="query" />
        </mat-form-field>
        <button class="form-button" type="button" (click)="searchSemantic(inputElement.value)" mat-raised-button>
            <fa-icon [icon]="icon"></fa-icon>
            Search
        </button>
    </div>
    <div class="flag">
     <div class="container-toggler box-height">
      <span class="">Complete</span>
      <mat-slide-toggle class="toggler col-md-4" (click)="completeStateToggler()" [formControl]="completeTogglerForm" color="primary">Add flag</mat-slide-toggle>
      <span class="">All</span>
     </div>
    </div>
</form>
