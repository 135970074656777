import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../user.service';
import { ApiService } from '../../../api.service';
import { map, take } from 'rxjs/operators';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-content-box',
  templateUrl: './content-box.component.html',
  styleUrls: ['./content-box.component.scss'],
})
export class ContentBoxComponent implements OnInit {
  public isAdmin: boolean = false;

  constructor(private userService: UserService, private api: ApiService) {}

  public ngOnInit(): void {
    this.isAdmin = this.userService.isAdmin;
  }

  nrOfLeads$ = this.api.getDocumentsCount().pipe(
    take(1),
    map((e) => e.count),
  );
  nrOfCompanies$ = this.api.getCompaniesCount().pipe(
    take(1),
    map((e) => e),
  );
  detectedPersonas$ = combineLatest([
    this.api.getDocumentsDetected().pipe(
      take(1),
      map((e) => e),
    ),
    this.nrOfLeads$,
  ]).pipe(
    map(([detected, leads]) => {
      return Number(detected / leads).toFixed(3);
    }),
  );

  public cards = [
    {
      title: 'Number of leads',
      value: this.nrOfLeads$,
      icon: 'bar_chart',
    },
    {
      title: 'Number of companies',
      value: this.nrOfCompanies$,
      icon: 'business',
    },
    {
      title: 'Detected personas',
      value: this.detectedPersonas$,
      icon: 'group',
    },
  ];
}
