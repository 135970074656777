import { Component, QueryList, ViewChildren, OnDestroy, OnInit, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';

import { FilterComponent } from '../filter/filter.component';
import { ApiService } from '../../../api.service';
import { take, takeUntil, startWith } from 'rxjs/operators';
import { UserService } from '../../../user.service';
import { Destroyable } from '../../destroyable.class';
import { Router } from '@angular/router';

@Component({
    selector: 'app-filters',
    templateUrl: './filters.component.html',
    styleUrls: ['./filters.component.scss']
})
export class FiltersComponent extends Destroyable implements OnDestroy, OnInit {
    @ViewChildren(FilterComponent) public filters: QueryList<FilterComponent>;
    @Output() public search: EventEmitter<string> = new EventEmitter<string>();

    showActiveFilters$ = this.api.showActiveFilters$.pipe(takeUntil(this.destroyed$));

    public whichFilters: string;
    public isMoreFiltersVisible: boolean = false;

    public countryOptions = [];
    public industryOptions = [];
    public companySizeOptions = [];
    public jobTitleOptions = [];
    public jobFunctionOptions = [];
    public revenueOptions = [];
    public lexicalsOptions = [];
    public personaeOptions = [];
    public personaeCOIFilterOptions = [];
    public personaeTopicsFilterOptions = [];

    selectedRevenues;
    selectedLexicals;
    selectedPersonae;
    selectedPersonaeCOIFilter;
    selectedPersonaeTopicsFilter = [];

    lexicalsFiltered;
    revenuesFiltered;
    personaeFiltered;
    personaeCOIFilterFiltered;
    personaeTopicsFilterFiltered;

    public templateFilters: any[] = [
        { query: 'Country', containerOptions: this.countryOptions },
        { query: 'Company_Industry', containerOptions: this.industryOptions },
        { query: 'Company_NB_Employees', containerOptions: this.companySizeOptions },
        { query: 'Job_Title', containerOptions: this.jobTitleOptions },
        { query: 'Job_Function', containerOptions: this.jobFunctionOptions },
        { query: 'Company_Lexicals_filter', containerOptions: this.lexicalsOptions, settings: 'lexicals' },
        { query: 'Company_Revenue', containerOptions: this.revenueOptions },
        { query: 'Personae', containerOptions: this.personaeOptions },
        { query: 'Personae_COI_filter', containerOptions: this.personaeCOIFilterOptions, settings: 'lexicals' },
        { query: 'Personae_Topics_filter', containerOptions: this.personaeTopicsFilterOptions, settings: 'lexicals' }
    ];

    awg(event) {

        // console.log(this.form.get('country').value?.length);
        // console.log(this.form.get('industry').value?.length);
        // console.log(this.form.get('companySize').value?.length);
        // console.log(this.form.get('jobTitle').value?.length);
        // console.log(this.form.get('jobFunction').value?.length);
        //
        // console.log(this.selectedPersonae?.length);
        // console.log(this.selectedPersonaeCOIFilter?.length);
        // console.log(this.selectedPersonaeTopicsFilter?.length);
    }

    deleteChips(item, containerArray) {
        if (containerArray.length > 0) {
            this.selectedLexicals === containerArray ? (this.selectedLexicals = containerArray.filter((el: string) => el !== item)) : null;
            this.selectedRevenues === containerArray ? (this.selectedRevenues = containerArray.filter((el: string) => el !== item)) : null;
            this.selectedPersonae === containerArray ? (this.selectedPersonae = containerArray.filter((el: string) => el !== item)) : null;
            this.selectedPersonaeCOIFilter === containerArray ? (this.selectedPersonaeCOIFilter = containerArray.filter((el: string) => el !== item)) : null;
            this.selectedPersonaeTopicsFilter === containerArray ? (this.selectedPersonaeTopicsFilter = containerArray.filter((el: string) => el !== item)) : null;
        }

        if (containerArray instanceof FormControl) {
            const val = containerArray.value;
            containerArray.setValue(val.filter((el: { name: string }) => el.name !== item.name));
        }

        this.submitFilters();
    }

    constructor(private fb: FormBuilder, private api: ApiService, private userService: UserService, private router: Router) {
        super();

        this.whichFilters = this.router.url.includes('lead-search') ? 'lead' : 'company';

        this.userService.token.pipe(take(2)).subscribe((e) => {
            if (e.length > 0) {
                this.templateFilters.map(({ query, containerOptions, settings }) =>
                    this.api
                        .getFilterDate(query, settings)
                        .pipe(take(1))
                        .subscribe((response: string[]) => {
                            const options = response
                                .map((el) => {
                                    return { name: el };
                                })
                                .sort((a, b) => (a.name > b.name ? 1 : -1))
                                .filter((el) => el.name !== '' && el.name !== '-');

                            options.map((e) => {
                                //companySize
                                if (containerOptions.length !== options.length && options.length === 7) {
                                    const data = [options[0], options[1], options[5], options[2], options[3], options[4], options[6]];
                                    data.forEach((el) => containerOptions.push(el));
                                }

                                //companyRevenue
                                if (containerOptions.length !== options.length && options.length === 9) {
                                    const data = [options[0], options[5], options[4], options[7], options[2], options[6], options[1], options[3], options[8]];
                                    data.forEach((el) => containerOptions.push(el));
                                }
                                if (containerOptions.length !== options.length && options.length !== 9 && options.length !== 7) containerOptions.push(e);
                            });
                        })
                );
            }
        });
    }

    filter(str: string, container: any[]): any[] {
        return container.filter((el) =>
            el.name
                .toLowerCase()
                .split(' ')
                .some((item) => item.startsWith(str.toLowerCase() || ''))
        );
    }

    ngOnInit(): void {
        this.api.search_phrase = null;
        this.form
            .get('companyLexicals')
            .valueChanges.pipe(takeUntil(this.destroyed$), startWith(this.lexicalsOptions))
            .subscribe((e) => {
                const filtered = this.filter(e || '', this.lexicalsOptions).sort((a, b) => (a.name > b.name ? 1 : -1));
                this.lexicalsFiltered = filtered.length > 0 && e !== '' ? filtered : this.lexicalsOptions;
            });

        this.form
            .get('companyRevenue')
            .valueChanges.pipe(takeUntil(this.destroyed$), startWith(this.revenueOptions))
            .subscribe((e) => {
                const filtered = this.filter(e || '', this.revenueOptions).sort((a, b) => (a.name > b.name ? 1 : -1));
                this.revenuesFiltered = filtered.length > 0 && e !== '' ? filtered : this.revenueOptions;
            });

        this.form
            .get('personae_coi')
            .valueChanges.pipe(takeUntil(this.destroyed$), startWith(this.personaeCOIFilterOptions))
            .subscribe((e) => {
                const filtered = this.filter(e || '', this.personaeCOIFilterOptions).sort((a, b) => (a.name > b.name ? 1 : -1));
                this.personaeCOIFilterFiltered = filtered.length > 0 && e !== '' ? filtered : this.personaeCOIFilterOptions;
            });

        this.form
            .get('personae_topics')
            .valueChanges.pipe(takeUntil(this.destroyed$), startWith(this.personaeTopicsFilterOptions))
            .subscribe((e) => {
                const filtered = this.filter(e || '', this.personaeTopicsFilterOptions).sort((a, b) => (a.name > b.name ? 1 : -1));
                this.personaeTopicsFilterFiltered = filtered.length > 0 && e !== '' ? filtered : this.personaeTopicsFilterOptions;
            });
    }

    submitFilters(): void {
        if ((this.form.get('country').value?.length > 0 ||
            this.form.get('industry').value?.length > 0 ||
            this.form.get('companySize').value?.length > 0 ||
            this.form.get('jobTitle').value?.length > 0 ||
            this.form.get('jobFunction').value?.length > 0 ||
            this.selectedLexicals?.length > 0 ||
            this.selectedRevenues?.length > 0 ||
            this.selectedPersonae?.length > 0 ||
            this.selectedPersonaeCOIFilter?.length > 0 ||
            this.selectedPersonaeTopicsFilter?.length > 0) === false) return

        this.api.showActiveFilters$.next(true);
        this.api.filterToAssign$.next({
            country:
                this.form.get('country').value?.length > 0
                    ? this.form
                        .get('country')
                        .value.map(({ name }) => name)
                        .join(',')
                    : '',
            company_industry:
                this.form.get('industry').value?.length > 0
                    ? this.form
                        .get('industry')
                        .value.map(({ name }) => name)
                        .join(',')
                    : '',
            company_nb_employees:
                this.form.get('companySize').value?.length > 0
                    ? this.form
                        .get('companySize')
                        .value.map(({ name }) => name)
                        .join(',')
                    : '',
            job_title:
                this.form.get('jobTitle').value?.length > 0
                    ? this.form
                        .get('jobTitle')
                        .value.map(({ name }) => name)
                        .join(',')
                    : '',
            job_function:
                this.form.get('jobFunction').value?.length > 0
                    ? this.form
                        .get('jobFunction')
                        .value.map(({ name }) => name)
                        .join(',')
                    : '',
            company_revenue: this.selectedRevenues?.join(',') ?? '',
            company_lexicals: this.selectedLexicals?.join(',') ?? '',
            personae: this.selectedPersonae?.join(',') ?? '',
            personae_coi: this.selectedPersonaeCOIFilter?.join(',') ?? '',
            personae_topics: this.selectedPersonaeTopicsFilter?.join(',') ?? '',
            // search_phrase: this.api.search_phrase?.search_phrase
        });

        this.search.emit(this.api.filterToAssign$.value);
    }

    toggleMoreFilters(event: MouseEvent | PointerEvent | any) {
        const className = event.target.className;

        if (this.isMoreFiltersVisible) {
            this.submitFilters();
        }

        this.isMoreFiltersVisible = !this.isMoreFiltersVisible;
    }

    public form = this.fb.group({
        country: [''],
        industry: [''],
        companySize: [''],
        jobTitle: [''],
        jobFunction: [''],
        companyRevenue: '',
        companyLexicals: '',
        personae: '',
        personae_coi: '',
        personae_topics: ''
    });

    ngOnDestroy() {
        this.resetFilters();
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    public resetFilters(): void {
        this.form.reset();
        this.selectedLexicals = [];
        this.selectedPersonae = [];
        this.selectedPersonaeCOIFilter = [];
        this.selectedPersonaeTopicsFilter = [];
        this.selectedRevenues = [];
        this.api.showActiveFilters$.next(false);
        this.api.filterToAssign$.next({
            country: '',
            company_industry: '',
            company_nb_employees: '',
            job_title: '',
            job_function: '',
            company_revenue: '',
            company_lexicals: '',
            personae: '',
            personae_coi: '',
            personae_topics: ''
        });
        this.submitFilters();
        // this.search.emit(this.api.filterToAssign$.value);
    }
}
