import { Component, Input, OnInit } from '@angular/core';
import { IPersonSearch } from '../../interfaces/person.interface';
import { ILeadSearch } from '../../interfaces/lead-search.interface';
import {
  faAt,
  faMobileAlt,
  faMapMarkerAlt,
  faMapMarker,
  faFlagUsa,
  faMapMarkedAlt,
} from '@fortawesome/free-solid-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-look-a-like-card',
  templateUrl: './look-a-like-card.component.html',
  styleUrls: ['./look-a-like-card.component.scss'],
})
export class LookALikeCardComponent implements OnInit {
  @Input() public leadResponse: ILeadSearch;

  public icons = {
    faAt,
    faMobileAlt,
    faMapMarkerAlt,
    faMapMarker,
    faFlagUsa,
    faMapMarkedAlt,
    faLinkedin,
  };
  public addressField: string;
  public cityStatePostalAddress: string;

  public ngOnInit(): void {
    this.addressField = `${this.leadResponse.Address1} ${this.leadResponse.Address2}`;
    this.cityStatePostalAddress = `${this.leadResponse.City} ${this.leadResponse.State} ${this.leadResponse.Postal_Code}`;
  }
}
