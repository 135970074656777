import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StripeService {
  constructor(private http: HttpClient) {}

  getPricing(): Observable<any> {
    return this.http.get(`${environment.api}/think-abm/stripe/prices`);
  }

  getProducts(): Observable<any> {
    return this.http.get(`${environment.api}/think-abm/stripe/products`);
  }

  createCheckoutSession(priceId: string): Observable<any> {
    return this.http.post(
      `${environment.api}/think-abm/stripe/create-checkout-session`,
      {
        priceId: priceId,
      },
    );
  }
}
