import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatChipsModule } from '@angular/material/chips';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

import { ContentBoxComponent } from './components/content-box/content-box.component';
import { ContentBoxCardComponent } from './components/content-box-card/content-box-card.component';
import { SemanticSearchCardComponent } from './components/semantic-search-card/semantic-search-card.component';
import { LookALikeCardComponent } from './components/look-a-like-card/look-a-like-card.component';
import { SearchBoxComponent } from './components/search-box/search-box.component';
import { PlanSingleColumnComponent } from './components/plan-single-column/plan-single-column.component';
import { AuthGuard } from './guards/auth.guard';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FilterComponent } from './components/filter/filter.component';
import { FiltersComponent } from './components/filters/filters.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SearchResultHeaderComponent } from './components/search-result-header/search-result-header.component';
import { KeywordComponent } from './components/keyword/keyword.component';
import { PaymentModalComponent } from './components/payment-modal/payment-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MultiSelectModule } from 'primeng/multiselect';
import { TooltipModule } from 'primeng/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CheckboxModule } from 'primeng/checkbox';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { TopBarComponent } from './components/top-bar/top-bar.component';
import { DataEnrichmentUploadComponent } from '../pages/data-enrichment/data-enrichment-upload/data-enrichment-upload.component';
import { DragDirective } from './directives/appDrag';
import { LoaderComponent } from './components/loader/loader.component';

const PRIMENG_MODULES: any[] = [
  MultiSelectModule,
  TooltipModule,
  CheckboxModule
]

const MATERIAL_MODULES: any[] = [
  MatSidenavModule,
  MatButtonModule,
  MatToolbarModule,
  MatIconModule,
  MatMenuModule,
  MatDividerModule,
  MatCardModule,
  MatInputModule,
  MatProgressSpinnerModule,
  MatTooltipModule,
  MatChipsModule,
  MatCheckboxModule,
  MatAutocompleteModule,
  MatDialogModule,
  MatSlideToggleModule,
  MatTableModule,
  MatPaginatorModule,
  MatSelectModule,
  MatSortModule,
  MatProgressSpinnerModule,
];

@NgModule({
  declarations: [
    ContentBoxComponent,
    ContentBoxCardComponent,
    SemanticSearchCardComponent,
    LookALikeCardComponent,
    SearchBoxComponent,
    PlanSingleColumnComponent,
    FilterComponent,
    FiltersComponent,
    SearchResultHeaderComponent,
    KeywordComponent,
    PaymentModalComponent,
    TopBarComponent,
    DataEnrichmentUploadComponent,
    DragDirective,
    LoaderComponent,
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    ...PRIMENG_MODULES,
    ...MATERIAL_MODULES,
    ReactiveFormsModule,
    FormsModule,
  ],
  exports: [
    ...MATERIAL_MODULES,
    FontAwesomeModule,
    ContentBoxComponent,
    SemanticSearchCardComponent,
    LookALikeCardComponent,
    SearchBoxComponent,
    PlanSingleColumnComponent,
    FilterComponent,
    FiltersComponent,
    SearchResultHeaderComponent,
    KeywordComponent,
    PaymentModalComponent,
    TopBarComponent,
    DataEnrichmentUploadComponent,
    DragDirective,
    LoaderComponent,
  ],
  providers: [AuthGuard],
})
export class SharedModule { }
