import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { loadStripe } from '@stripe/stripe-js/pure';
import { StripeService } from 'src/app/stripe.service';

@Component({
  selector: 'app-plan-single-column',
  templateUrl: './plan-single-column.component.html',
  styleUrls: ['./plan-single-column.component.scss'],
})
export class PlanSingleColumnComponent {
  @Input() public planName: string;
  @Input() public price: number;
  @Input() public subtitle: string;
  @Input() public bulletpoints: string[];
  @Input() public action: string;
  @Input() public logoSrc: string;

  constructor(private router: Router, private stripe: StripeService) {}

  public navigate(): void {
    this.stripe
      .createCheckoutSession(this.action)
      .toPromise()
      .then((res) => {
        const Stripe = loadStripe(
          'pk_test_51IVdQYHbjGILbXv72OQA9G6gjFninOs66xllyKzfc2cjRCXz7huoJFlbEjdVZplse8hTBBthpcSYMKerc1a2532l00SGe5xuWc',
        );
        Stripe.then((stripe) =>
          stripe.redirectToCheckout({
            sessionId: res.sessionId,
          }),
        ).then((r) => console.log(r));
      });
  }
}
