import { ChangeDetectorRef, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApiService } from './api.service';
import { Destroyable } from './shared/destroyable.class';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends Destroyable {
  public isLoading$: Observable<boolean> = this.apiService.isLoading$;

  constructor(private apiService: ApiService, private cdRef: ChangeDetectorRef) {
    super();
    this.isLoading$.pipe(takeUntil(this.destroyed$)).subscribe(() => this.cdRef.markForCheck());
  }
}
