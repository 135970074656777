<div class="container-fluid d-flex align-items-center top-bar-alignment">
    <div class="sec-flex">
        <p class="title">{{ title }}</p>

        <div class="icons">
            <!-- <button mat-button class="action-button" *ngIf="campaignDetails || isDetails" (click)="editDetails()"> <img class="margin-right-half" src="assets/svgs/edit.svg" /> Edit </button> -->
            <div class="ellipsis">
                <img src="assets/svgs/user.svg" />
                <div class="logout">
                    <span (click)="logout()">logout</span>
                </div>
            </div>
        </div>
    </div>
</div>
