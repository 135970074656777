<mat-card class="d-flex flex-column-reverse" *ngIf="leadResponse">
  <div class="d-flex justify-content-between">
    <div class="d-flex flex-column col-md-12 card-with-overflow">
      <h5 class="mb-0 job-title-row gray-text ffws700">
        {{ leadResponse.Job_Title }}
      </h5>
      <span class="name-row d-flex align-items-center ffws700"
        >{{ leadResponse.First_Name }} {{ leadResponse.Last_Name }}</span
      >

      <div class="w-100">
        <div>
          <p class="info-row grid-important grid-detail mp-clear">
            <fa-icon [icon]="icons.faAt"></fa-icon>
            <span
              class="text-truncate"
              *ngIf="leadResponse.Email !== ' ' && leadResponse.Email !== ''"
              >{{ leadResponse.Email }}</span
            >
            <span
              class="text-truncate"
              *ngIf="leadResponse.Email === ' ' || leadResponse.Email === ''"
              >n/a</span
            >
          </p>
          <p class="info-row grid-important grid-detail mp-clear">
            <fa-icon class="phone-icon" [icon]="icons.faMobileAlt"></fa-icon>
            <span
              class="text-truncate"
              *ngIf="leadResponse.Phone !== ' ' && leadResponse.Phone !== ''"
              >{{ leadResponse.Phone }}</span
            >
            <span
              class="text-truncate"
              *ngIf="leadResponse.Phone === ' ' || leadResponse.Phone === ''"
              >n/a</span
            >
          </p>
        </div>

        <div class="fixed-text">
          <p class="info-row name-row justify-content-end">
            <span class="text-end text-truncate ffws700">{{
              leadResponse.Company_Name
            }}</span>
          </p>
          <p class="info-row jc-e">
            <span class="job-title-row gray-text text-end text-truncate">{{
              leadResponse.Company_Industry
            }}</span>
          </p>
        </div>
      </div>
      <p class="info-row grid-important grid-detail mp-clear">
        <fa-icon [icon]="icons.faLinkedin"></fa-icon>
        <a
          class="card-link info-row text-color text-truncate"
          [href]="leadResponse.Linked_In_Url"
          target="_blank"
          >{{ leadResponse.Linked_In_Url || "n/a" }}</a
        >
      </p>
      <p
        class="info-row grid-important grid-detail mp-clear"
        [matTooltip]="addressField"
      >
        <fa-icon [icon]="icons.faMapMarkerAlt"></fa-icon>
        <span
          class="text-truncate"
          *ngIf="addressField !== '- ' && addressField !== ' '"
          >{{ addressField }}</span
        >
        <span
          class="text-truncate"
          *ngIf="addressField === '- ' || addressField === ' '"
          >n/a</span
        >
      </p>

      <p
        class="info-row grid-important grid-detail mp-clear"
        [matTooltip]="cityStatePostalAddress"
      >
        <fa-icon class="marked-icon" [icon]="icons.faMapMarkedAlt"></fa-icon>
        <span
          class="text-truncate"
          *ngIf="cityStatePostalAddress !== '- - -'"
          >{{ cityStatePostalAddress }}</span
        >
        <span class="text-truncate" *ngIf="cityStatePostalAddress === '- - -'"
          >n/a</span
        >
      </p>
      <p class="info-row grid-important grid-detail mp-clear">
        <fa-icon [icon]="icons.faFlagUsa"></fa-icon>
        <span class="text-truncate">{{ leadResponse.Country || "n/a" }}</span>
      </p>
    </div>
  </div>
</mat-card>
