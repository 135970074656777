<div class="container-fluid box d-flex flex-column justify-content-center additional-container-padding">
    <div class="row" [ngClass]="{ 'additional-margin': !isAdmin }">
        <ng-container *ngIf="isAdmin">
            <div class="col-md-4 pb-2" *ngFor="let card of cards">
                <app-content-box-card [title]="card.title" [value]="card.value" [percentValue]="card.percentValue" [icon]="card.icon"></app-content-box-card>
            </div>
        </ng-container>

        <ng-content></ng-content>
    </div>
</div>
