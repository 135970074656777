import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ApiService } from '../../../api.service';
import { Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {
  @Input() public form: FormGroup;
  @Input() public label: string;
  @Input() public controlName: string;

  public icon: typeof faTimes = faTimes;
  public options: string[] = [];
  public options$: Observable<string[]>;
  public selected: boolean = false;

  constructor(private apiService: ApiService) { }

  public ngOnInit(): void {
    let label: string = this.label;

    switch (label) {
      case 'Industry': {
        label = 'Company_Industry';
        break;
      }
      case 'Company Size': {
        label = 'Company_NB_Employees';
        break;
      }
      case 'Company Revenue': {
        label = 'Company_Revenue';
        break;
      }
    }

    this.apiService
      .getFilterDateFromIdentify(label)
      .toPromise()
      .then((value) => {
        const e = value;
        let sortedRevenue = null;
        let sortedCompanySize = null;

        if (e.length === 14) {
          sortedRevenue = [e[1], e[5], e[2], e[3], e[4], e[6], e[7], e[12], e[9], e[10], e[13], e[11]];
        }

        if (e.length === 11) {
          sortedCompanySize = [e[9], e[6], e[5], e[2], '251-500', e[1], e[7], e[3], e[4], e[10], e[0], ''];
        }

        this.options = sortedRevenue || sortedCompanySize || e.sort();
        this.options$ = of(this.options);

        this.options$ = this.form.get(this.controlName).valueChanges.pipe(
          startWith(''),
          map((val) => this.filter(val))
        );
      });

    this.form.get(this.controlName).setValue('');
  }

  public onFilterSelected(event): void {
    this.selected = true;
    if (this.apiService.filters.map((el) => el.key).includes(`${this.label}.keyword`)) {
      const itemIndex: number = this.apiService.filters.findIndex((el) => el.key === `${this.label}.keyword`);
      this.apiService.filters[itemIndex] = {
        key: `${this.label}.keyword`,
        value: event.option.value
      };
    } else if (
      !this.apiService.filters.includes({
        key: `${this.label}.keyword`,
        value: event.option.value
      })
    ) {
      this.apiService.filters.push({
        key: `${this.label}.keyword`,
        value: event.option.value
      });
    }
  }

  public reset(): void {
    this.apiService.filters = this.apiService.filters.filter((el) => el.value !== this.form.get(this.controlName).value);
    this.form.get(this.controlName).setValue('');
    this.selected = false;
  }

  private filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter((option) => option.toLowerCase().includes(filterValue));
  }
}
