import { Component, Renderer2, ElementRef, ViewChild, OnDestroy, AfterViewInit, Input, Inject, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { take, catchError } from 'rxjs/operators';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-data-enrichment-upload',
    templateUrl: './data-enrichment-upload.component.html',
    styleUrls: ['./data-enrichment-upload.component.scss']
})
export class DataEnrichmentUploadComponent implements AfterViewInit, OnDestroy {
    @ViewChild('upload', { static: false }) input: ElementRef;
    @Input() title: string;
    @Output() chips = new EventEmitter();

    file$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    isCorrectType: boolean = null;
    isFileSizeCorrect: boolean = null;
    isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    // downloadHref: string = '';

    private inputHandler: () => void;

    constructor(private renderer2: Renderer2, private router: Router, private dialog: MatDialog) {}

    ngAfterViewInit(): void {
        // const id = this.api.getCampaignID();
        const el = this.input.nativeElement;
        // this.downloadHref = `app/campaigns/${id}/download-leads`;

        this.inputHandler = this.renderer2.listen(el, 'change', () => {
            this.checkFile((<HTMLInputElement>el).files[0]);
            this.file$.next((<HTMLInputElement>el).files[0]);
        });
    }

    ngOnDestroy() {
        this.inputHandler();
    }

    checkFile(file: File): void {
        const splitted = file.name.split('.').pop();
        // const id = this.api.getCampaignID();

        this.isCorrectType = splitted === 'xlsx' || splitted === 'csv';
        this.isFileSizeCorrect = file.size / 1024 / 1024 < 11;

        if (!this.isCorrectType) {
            this.openStatusUploadModal('File format is not correct', false);
        }

        if (this.isCorrectType && this.isFileSizeCorrect) {
            const dataToUpload = new FormData();
            dataToUpload.append('file', file);

            if (this.router.url.includes('leads')) {
                // this.api
                //     .uploadAssets(id, dataToUpload)
                //     .pipe(
                //         take(1),
                //         catchError(async (e) => this.openStatusUploadModal(e, false))
                //     )
                //     .subscribe((e) => {
                //         if (e instanceof Object) {
                //             this.openStatusUploadModal('New leads were successfully uploaded', true);
                //         } else {
                //             this.openStatusUploadModal('Leads were not uploaded. Please try again.', false);
                //         }
                //     });
            }

            if (this.title.toLowerCase().includes('dnc')) {
                // this.api
                //     .uploadDNC(dataToUpload)
                //     .pipe(
                //         take(1),
                //         catchError(async () => this.openStatusUploadModal('DNC list was not uploaded. Please try again.', false))
                //     )
                //     .subscribe(() => {
                //         this.openStatusUploadModal('DNC list was successfully uploaded', true);
                //     });
            }
        }
    }

    openStatusUploadModal(info: string, status: boolean) {
        const dialogRef = this.dialog.open(StatusUploadModalComponent, {
            data: { title: info, status: status },
            height: '60px',
            width: '540px',
            position: { top: '49vh', left: '40vw' }
        });

        dialogRef.afterClosed().subscribe((result) => {});
    }

    filesDropped(file: File): void {
        this.checkFile(file);
        this.file$.next(file);
    }
}

@Component({
    selector: 'app-status-upload-modal',
    templateUrl: './modal/status-upload-modal.html',
    styleUrls: ['./modal/status-upload-modal.scss']
})
export class StatusUploadModalComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public data: { title: string; status: boolean }) {}
}
