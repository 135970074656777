import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-keyword',
  templateUrl: './keyword.component.html',
  styleUrls: ['./keyword.component.scss'],
})
export class KeywordComponent {
  @Input() public textValue: string;
}
